export default {
  pages: {
    key: "title",
    data: [{
      title: 'Home',
      url: '/',
      icon: 'HomeIcon',
      is_bookmarked: false
    }]
  }
}
