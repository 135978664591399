/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "home",
          component: () => import("./views/Home.vue")
        },
        {
          path: "/industry",
          name: "industry",
          component: () => import("./views/Industry.vue")
        },
        //Genealogy
        {
          path: "/",
          redirect: "/genealogy/index"
        },
        //Binary tree
        {
          path: "/genealogy/index",
          name: "index",
          component: () => import("./views/genealogy/index.vue")
        },
        //Lyra - Binary tree
        {
          path: "/genealogy/new-binary",
          name: "new-binary",
          component: () => import("./views/genealogy/new-binary.vue")
        },
        //Detail Binary tree
        {
          path: "/genealogy/detail",
          name: "detail",
          component: () => import("./views/genealogy/detail.vue")
        },
        //View ITA status by period
        {
          path: "/genealogy/statusByPeriod",
          name: "statusByPeriod",
          component: () => import("./views/genealogy/statusByPeriod.vue")
        },
        //Orders
        {
          path: "/orders/index",
          name: "orders-index",
          component: () => import("./views/orders/index.vue")
        },
        //User
        {
          path: "/pages/profile",
          name: "page-profile",
          component: () => import("@/views/pages/Profile.vue")
        },
        //General
        {
          path: "/",
          redirect: "/commissions/Index"
        },
        {
          path: "/commissions/Index",
          name: "commissions",
          component: () => import("./views/commissions/Index.vue")
        },
        {
          path: "/commissions/DetailTotal/:period:transaction:date",
          name: "commissionsDetailTotal",
          component: () => import("./views/commissions/DetailTotal.vue")
        },
        {
          path: "/commissions/Customized",
          name: "Customized",
          component: () => import("./views/commissions/Customized.vue")
        },
        {
          path: "/commissions/NewCustomized",
          name: "CustomizedO2T",
          component: () => import("./views/commissions/NewCustomized.vue")
        },
        {
          path: "/commissions/DetailCustomized",
          name: "DetailCustomized",
          component: () => import("./views/commissions/DetailCustomized.vue")
        },
        //Bookings
        {
          path: "/bookings",
          name: "bookings",
          component: () => import("./views/bookings/index.vue")
        },
        {
          path: "/bookings/index",
          name: "booking-index",
          component: () => import("./views/bookings/index.vue")
        },
        {
          path: "/bookings/detail/:bookingId",
          name: "bookingDetail",
          component: () => import("./views/bookings/detail.vue")
        },
        {
          path: "/bookings/summary",
          name: "booking-summary",
          component: () => import("./views/bookings/summary.vue")
        },
        {
          path: "/bookings/index-managed",
          name: "booking-index-managed",
          component: () => import("./views/bookings/index-managed.vue")
        },
        {
          path: "/bookings/byReference",
          name: "booking-byReference",
          component: () => import("./views/bookings/byReference.vue")
        },
        {
          path: "/bookings/customer",
          name: "booking-customer",
          component: () => import("./views/bookings/customer.vue")
        },
        {
          path: "/bookings/create",
          name: "booking-create",
          component: () => import("./views/bookings/create.vue")
        },
        //Purchases
        {
          path: "/purchases",
          name: "purchases",
          component: () => import("./views/purchases/index.vue")
        },
        {
          path: "/purchases/index",
          name: "purchases-index",
          component: () => import("./views/purchases/index.vue")
        },
        {
          path: "/purchases/neo",
          name: "purchases-neo",
          component: () => import("./views/purchases/neo.vue")
        },
        {
          path: "/purchases/membership/:purchaseId?",
          name: "purchases-membership",
          component: () => import("./views/purchases/membership.vue")
        },
        {
          path: "/purchases/upgrade/:purchaseId?",
          name: "purchases-upgrade",
          component: () => import("./views/purchases/upgrade.vue")
        },
        {
          path: "/purchases/monthlyPayment/:purchaseId?",
          name: "purchases-monthlyPayment",
          component: () => import("./views/purchases/monthlyPayment.vue")
        },
        {
          path: "/purchases/affiliations",
          name: "purchases-affiliations",
          component: () => import("./views/purchases/affiliations.vue")
        },
        {
          path: "/purchases/products/:purchaseId?",
          name: "purchases-products",
          component: () => import("./views/purchases/products.vue")
        },
        //Neo
        {
          path: "/tracer",
          name: "tracer",
          component: () => import("./views/tracer/index.vue")
        },
        {
          path: "/tracer/index",
          name: "tracer-index",
          component: () => import("./views/tracer/index.vue")
        },
        {
          path: "/tracer/detail/:tracerId",
          name: "tracer-detail",
          component: () => import("./views/tracer/detail.vue")
        },
        {
          path: "/tracer/summary",
          name: "tracer-summary",
          component: () => import("./views/tracer/summary.vue")
        },
        {
          path: "/tracer/guides",
          name: "tracer-guides",
          component: () => import("./views/tracer/guides.vue")
        },
        //Coupon
        {
          path: "/coupon/index",
          name: "coupon-index",
          component: () => import("./views/coupon/index.vue")
        },
        //Resources
        {
          path: "/resources",
          name: "resources",
          component: () => import("./views/resources/news.vue")
        },
        {
          path: "/resources/marketing",
          name: "resources-marketing",
          component: () => import("./views/resources/marketing.vue")
        },
        {
          path: "/resources/marketing-detail/:blogTypeId/:blogTypeName",
          name: "resources-marketing-detail",
          component: () => import("./views/resources/marketing-detail.vue")
        },
        {
          path: "/resources/news",
          name: "resources-news",
          component: () => import("./views/resources/news.vue")
        },
        {
          path: "/resources/news/list/:contentTypeId",
          name: "resources-news-list",
          component: () => import("./views/resources/news-list.vue")
        },
        {
          path: "/resources/news/detail/:id:contentTypeId",
          name: "resources-news-detail",
          component: () => import("./views/resources/news-detail.vue")
        },
        {
          path: "/resources/faq",
          name: "resources-faq",
          component: () => import("./views/resources/faq.vue")
        },
        {
          path: "/resources/download",
          name: "resources-download",
          component: () => import("./views/resources/download.vue")
        },
        {
          path: "/resources/exchangeRate",
          name: "resources-exchangeRate",
          component: () => import("./views/resources/exchangeRate.vue")
        },
        ///
        {
          path: "/blockPay",
          name: "blockPay",
          component: () => import("./views/blockPay/index.vue")
        },
        {
          path: "/blockPay/index",
          name: "blockPay-index",
          component: () => import("./views/blockPay/index.vue")
        },
        {
          path: "/blockPay/send",
          name: "blockPay-send",
          component: () => import("./views/blockPay/send.vue")
        },
        {
          path: "/bookings/incentive-commissions",
          name: "bookings-incentive-commissions",
          component: () => import("@/views/bookings/incentive-commissions.vue")
        },
        // Landing
        {
          path: "/landing/prospects",
          name: "landing-prospects",
          component: () => import("@/views/landing/prospect.vue")
        },
        {
          path: "/landing/management",
          name: "landing-management",
          component: () => import("@/views/landing/management.vue")
        },
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/Login.vue")
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue")
        },
        {
          path: "/pages/forgot-password",
          name: "page-forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor"
          }
        }
        // {
        //   path: "/purchases/autoMembership/:iboCode",
        //   name: "purchases-autoMembership",
        //   component: () => import("./views/purchases/autoMembership.vue")
        // },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404"
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  let expiry = new Date(localStorage.getItem("tokenExpiry") * 1000);
  let expiryVT = new Date(localStorage.getItem("tokenExpiryVT") * 1000);
  if (
    to.name == "purchases-autoMembership" ||
    to.path === "/pages/login" ||
    to.path === "/pages/error-404" ||
    to.path == "/pages/forgot-password" ||
    to.path == "/pages/reset-password" ||
    (new Date(Date.now()) < expiry &&
      localStorage.getItem("loggedIn") != "" &&
      new Date(Date.now()) < expiryVT &&
      localStorage.getItem("tokenVT") != "")
  ) {
    return next();
  }
  router.push({
    path: "/pages/login",
    query: {
      to: to.path
    }
  });
});

export default router;
