// en, es
export default {
  en: {
    // ------------------------------- Alerts and messages -------------------------------
    MsgError: "Please,contact with the system administrator",
    DontHaveCycles: "You haven't made cycles",
    InformationEarnings:
      "If you need to view more information about your earnings by period, please, select the table row. The default data in the view is the last year",
    ColorsTableGenealogy:
      'The "Level" column has its values with colors, the meaning is:',
    MsgNotAllow10Rows: "It only allows maximum 9 levels",
    MsgDistNotExist: "The Distributor ID doesn't exist in your binary tree",
    MsgWelcome: "Welcome back, please login to your account",
    MsgRecoverPassword:
      "Please enter your email address and we'll send you a verification code to reset or update your password.",
    MsgNewPassword: "Please, enter your new password",
    MsgDifferentPassword: "The passwords are differents",
    MsgInvalidPassword:
      "The password must have minimum 9 characters with uppercase, lowercase, numbers and special characters, else the button will not active",
    MsgNotBinaryTree:
      "It has occurred a fail to recover the binary tree. Information isn't correct. Please, check with the administrator",
    MsgFromLevelLess: 'The "{0}" field must be less than "{1}"',
    MsgUserNotExist: "The user isn't a distributor",
    MgsRestorePassword:
      'You can restore your password with the option "Forgot your password?"',
    MsgNotFoundInfo: "Doesn't exist information",
    MsgStatusByPeriod:
      "You can search your qualification or another distributor of your downline in different periods",
    MsgConsiderInformation:
      "Please, you consider the next important information:",
    MsgPayTransaction: "USD $1 will be charged for each transfer requested",
    MsgDaysToPayment:
      "Transfers will be available on your ewallet the next Wednesday after transfer was requested at 16:00 ET or later.",
    MsgPaymentMade: "Payment have been made correctly",
    MsgPaymentNotMade: "Payment haven't been made correctly",
    MsgRefreshPage: "Please, refresh the page to get changes",
    MsgNotEnoughMoney: "You must have least USD $2 to do transfer.",
    MsgNotPayment: "We are not processing transfers request at this time",
    MsgNotTransferEwallet: "We are not processing transfers request to Ewallet at this time",
    MsgWalletNotExist:
      "The user {0} doesn't exist in E-wallet. Please, you need to create an account to receive payments.",
    MsgYouHaveEarned: "You have earned",
    MsgTipAndFee: "at tips and customized fee",
    MsgStageBill: "Only bookings are on billing will be paid",
    MsgTax:
      'Taxes will be charged for each booking. View "Tax" column in the detail',
    MsgTotalPendingCharges:
      "Will be charged to your transfer. You could there is received double payments or we didn't do the discount USD $1 por other transfers made",
    MsgWriteToCustomerService:
      "If you have something question, you must write to servicioalcliente@visiontravel.net with the description your issue",
    MsgOneTransferAtDay: "You only can make one transfer at day",
    MsgRequiredField: "You must fill in the required fields",
    MsgCustomizeTheme: "Customize and preview in real time",
    MsgTypeTraceGuide:
      "If you create a GENERIC LINK it means that, you will send a traceable link, and you can send it to multiple people at the time. Then the prospect will be asked for some basic information to see the interactive presentation, which will be recorded in your system.",
    MsgTypeTracer:
      "If you create a UNIQUE LINK it means that, you will send a unique tracker for a single person and you must enter some basic data of the prospect.",
    MsgSaved: "The record has been saved success",
    MsgNotAllowGenealogy:
      "You can't see the information about Genealogy. If you want getting the data, you must update your plan or do your monthly payment",
    MsgHaveNeo: "You already have Neo application",
    MsgAccountDiscount:
      "We'll deduct the purchase payment in the follow account",
    MsgWorking: "We're working in a new funcionality. Wait it soon",
    MsgAllRequiredField: "All fields are required",
    FeeNote:
      "This option will allow you to configure the additional fee that will be added to the total value of the reservation. This value will be subsequently entered in your ewallet account.",
    MoreInformation: "More information",
    TermsConditionsTitle: "Terms and conditions",
    TermsConditionsContent1: "* Read carefully the content of this text",
    TermsConditionsContent2: "WHAT CAN I DO WITH THIS FORM?",
    TermsConditionsContent3:
      "Configure the custom fee for available accommodations in the different PCC that are displayed in the virtual travel agency.",
    TermsConditionsContent4:
      "Configure the custom fee for accommodations, flights, insurance, automobiles, and packages of the United States PCC.",
    TermsConditionsContent5:
      "Configure the percentage between 1 and 10 for the custom fee.",
    TermsConditionsContent6:
      "If you want to modify the configured fee, you must delete the saved record from the option available in the 'Options' column and reconfigure the fee for that product and PCC.",
    TermsConditionsContent7: "WHERE DOES IT APPLY?",
    TermsConditionsContent8:
      "The custom fee will be added to the administrative charges of the reservation, thus increasing the total value of the reservation.",
    TermsConditionsContent9: "WHEN WILL IT BE REFLECTED ON MY ACCOUNT?",
    TermsConditionsContent10:
      "The additional fee charged on a reservation will be charged to your e-wallet account on the dates established by Vision Travel.",
    TermsConditionsContent11:
      "Keep in mind that the value you will receive for the personalized fee will be less than the amount charged, due to the costs of financial intermediation.",
    TermsConditionsContent12:
      "HOW DO I DISPLAY THE ADDITIONAL FEE APPLIED TO A RESERVATION?",
    TermsConditionsContent13:
      "You will be able to see the value of the fee applied to a reservation when entering into the detail of the reservation from the online tourism office.",
    TermsConditionsContent14: "WARNING...",
    TermsConditionsContent15:
      "Remember that the reservation prices must be within those established in the market. Misuse of the custom fee option may cause permanent blocking for application of the configured values.",
    MsgCopy: "Text copied successfully",
    MsgAcquireMembership:
      "You are adding a new member to your community with the {0} product for",
    MsgAcquireMembership1: "Are you ready to be part of the best community?",
    MsgAcquireMembership2: "Join right now !",
    MsgPendingMembership: "You owe {0} membership years",
    MsgPurchaseVerify:
      "Your purchase will be verified. Please, you must checked your Virtual Office to know request status",
    MsgPurchaseCompleted: "Your request has completed successfully",
    MsgNotUpgrade: "You don't apply to do an upgrade",
    MsgNotAttachment: "You must upload an attachment",
    MsgEmailSent: "Email has been sent successfully",
    MsgSendContract:
      "We have send the membership contract at email {0}. It must be signed by {1} to complete register successfully.",
    MsgNotMonthlyPayment:
      "You don't apply to monthly payment. Your expiration date is: {0}",
    MsgInfotraxOffline:
      "We apologize for the inconvenience caused, at this time our commissions and genealogy system is not working properly",
    MsgTransferAccount: "Transfer to the account #{0}",
    MsgJoinTelegram: "You can join to our community Telegram for receiving notifications about your purchases, they can be about tourism or binary. Don't worry, they'll only be related with your activity",
    MsgWhatWait: "What are you waiting for? Join up!",
    MsgGenerate: "Remember, when you pay online and the payments are confirmed (E-wallet), You only must do click in the 'Generate' button and that's all, your order and accesses have been generated. Make it easy!",
    MsgLinkMembership: "You can send this link to your customers and they'll do their affiliation. You must be attent to complete the binary information, if you want to receive the notification when the customer finish the process, please, Join to our Telegram community!. Click in the TELEGRAM button or in your profile.",
    MsgNotInvest: "The system has failed for completing the investment process on Block Invest",
    MsgInvest: "Congratulations! You've done an investment on Block Invest. GOOD LUCK WITH THE EARNINGS!",
    MsgNotBalance: "The account number {0} has balance USD {1}, is less than the amount",
    MsgFinancialCharge: "We will charge you a percentage of the total transaction of each personalized fee for 'Financial Charges', this by virtue of the collection of money...",
    MsgFinancialChargeAdded: "The added percentage that goes from 1% to 7% of fee, will have a fixed charge of 7%",
    MsgFinancialChargeFee: "The custom fee percentage ranging from 8% to 15% will have a charge equal to the percentage you added",
    MsgStartDateAfterEndDate: "Start date must less than end date",
    MsgItineraryBeforeNow: "Itineraries dates must equals or greater than current date",
    MsgItineraryAfterArrival: "Departure date must less than arrival date",
    //------------------------------- Menu  -------------------------------
    Title: "My office",
    Home: "Home",
    AccessToPlatforms: "Access to platforms",
    Genealogy: "Genealogy",
    Orders: "Orders",
    General: "General",
    Commissions: "Commissions",
    StatusByPeriod: "Status by period",
    Customized: "Customized",
    TravelPoints: "Travel points",
    //------------------------------- Descriptions -------------------------------
    Description: "Description",
    CarryInLastPeriod: "Carry in from last period",
    CurrentPeriod: "Current period",
    CarryNextPeriod: "Carry forward to next period",
    BusinessCenters: "Businesses centers",
    BusinessCenter: "Business center",
    Center: "Center",
    NewCustomers90: "New customers the last 90 days",
    DirectSales90: "Direct sales the last 90 days",
    DirectSales365: "Direct sales the last 365 days",
    Last5Periods: "Last five periods",
    Number: "Number",
    PVAmount: "PV Amount",
    Period: "Period",
    DistCountryCode: "Dist. country code",
    ShipToCountry: "Ship to country",
    ShipToName: "Ship to name",
    Date: "Date",
    Options: "Options",
    Next: "Next",
    Back: "Back",
    Finish: "Finish",
    LastWeek: "Last week",
    Currently: "Currently",
    Total: "Total",
    Volumen: "Volumen",
    Available: "Available",
    Profile: "Profile",
    View: "View",
    Download: "Download",
    Downloads: "Downloads",
    OrderHistory: "Orders history",
    BasicInformation: "Basic information",
    DocumentNumber: "Document number",
    BirthDate: "Birth date",
    Email: "Email",
    Mobile: "Mobile",
    Gender: "Gender",
    CountryOperation: "Country operation",
    Nationality: "Nationality",
    ResidenceCity: "Residence city",
    Address: "Address",
    Return: "Return",
    AllRightsReserved: "All rights reserved",
    HandCraftedMMade: "Hand-craft and made with",
    Invoice: "Invoice",
    OrderNumber: "Order number",
    OrderDate: "Order date",
    ShipTo: "Ship to",
    BillTo: "Bill to",
    Item: "Item",
    Code: "Code",
    QtyShip: "Quantity ship",
    QtyOrder: "Quantity order",
    TotalVolumen: "Total volumen",
    Price: "Price",
    Tax: "Tax",
    Taxable: "Taxable",
    Subtotal: "Subtotal",
    Comments: "Comments",
    ShippingAndHandling: "Shipping and handling",
    TotalAmountDue: "Total amount due",
    PaymentsReceived: "Payments received",
    ReceiptAmount: "Receipt amount",
    TotalCashReceipts: "Total cash receipts",
    InvoceBalance: "Invoice balance",
    Close: "Close",
    NoAvailableData: "No available data",
    LeftVolumen: "Left volumen",
    RightVolumen: "Right volumen",
    ExportAs: "Export as",
    Logout: "Logout",
    LeftBvTravelPoint: "Left - Travel points (BV)",
    RightBvTravelPoint: "Right - Travel points (BV)",
    Qualifications: "Qualifications",
    Rank: "Rank",
    VolumenPersonal: "Personal volumen",
    ViewEnrolledsTotal: "View enrollees total",
    CyclesRequired: "Cycles required",
    CyclesTotal: "Cycles total made",
    PersonalEnrollees: "Personal enrollees",
    Left: "Left",
    Right: "Right",
    SinceWeek: "Since week",
    UntilWeek: "To week",
    Select: "Select",
    Search: "Search",
    Summary: "Summary",
    StartDate: "Start date",
    EndDate: "End date",
    CheckDate: "Check date",
    CheckAmount: "Check amount",
    EarningsAmount: "Earnings amount",
    CurrencyCode: "Currency code",
    Currency: "Currency",
    TransactionNumber: "Transaction number",
    EarningsSummaryByPeriod: "Earnings summary by period",
    RetailCommission: "Retail commission",
    FastStart: "Fast start",
    CycleCommission: "Cycle commission",
    MatchingBonus: "Matching bonus",
    BaseBonus: "Base bonus",
    LifestyleBonus: "Lifestyle bonus",
    EarningsByCommissionType: "Earnings by commission type",
    PeriodDetails: "Period details",
    DownloadPDF: "Download PDF",
    Detail: "Detail",
    DistributorID: "Distributor ID",
    Name: "Name",
    PaidRank: "Paid rank",
    PinRank: "Pin rank",
    PercentContributed: "Percent contributed",
    AmountContributed: "Amount contributed",
    Amount: "Amount",
    Associated: "Associated",
    Silver: "Silver",
    Gold: "Gold",
    Platinum: "Platinum",
    Diamond: "Diamond",
    DoubleDiamond: "Double Diamond",
    TripleDiamond: "Triple Diamond",
    Crown: "Crown",
    CrownAmbassador: "Crown Ambassador",
    TotalCommissionsEarned: "Total commissions earned",
    Country: "Country",
    Percent: "Percent",
    IsPercent: "Is percent",
    Level: "Level",
    PaidLevel: "Paid level",
    Bonus: "Bonus",
    CycleNumber: "Cycle number",
    Step: "Step",
    BinaryTree: "Binary tree",
    Status: "Status",
    RequiredEnrolleds: "Required enrollees",
    EndRank: "End rank",
    Side: "Side",
    Sponsor: "Sponsor",
    FromLevel: "From level",
    UntilLevel: "To level",
    All: "All",
    Customer: "Customer",
    Customers: "Customers",
    Terminated: "Terminated",
    Distributor: "Distributor",
    MemberType: "Member type",
    Suspended: "Suspended",
    AmountRows: "Amount rows",
    Alert: "Alert",
    Error: "Error",
    Success: "Success",
    Start: "Start",
    Unclassified: "Unclassified",
    Login: "Login",
    Password: "Password",
    ForgotPassword: "Forgot your password?",
    RecoverPassword: "Recover your password",
    Recover: "Recover",
    ConfirmPassword: "Confirm password",
    VerificationCode: "Verification code",
    ResetPassword: "Reset password",
    Reset: "Reset",
    CustomizedCommissions: "Customized commissions",
    TipAndCustomizedFee: "Tips and customized fee",
    FeeTotal: "Fee total",
    TipsTotal: "Tips total",
    Pay: "Pay",
    Paid: "Paid",
    WithoutPay: "Without pay",
    BookingCode: "Booking code",
    CreationDate: "Creation date",
    PaymentDate: "Payment date",
    Type: "Type",
    Tip: "Tip",
    CustomizedFee: "Customized fee",
    AdjustmentTip: "Tip adjustment",
    AdjustmentCustomizedFee: "Customized fee adjustment",
    Transfer: "Transfer",
    Congratulations: "Congratulations",
    TotalTransactions: "Total transactions",
    TotalPendingCharges: "Total pending charges",
    PendingCharges: "Pending charges",
    FinancialCharge: "Financial charge",
    Action: "Action",
    Update: "Update",
    Save: "Save",
    ThemeCustomized: "Theme Customized",
    LayoutType: "Layout  type",
    Vertical: "Vertical",
    Horizontal: "Horizontal",
    Color: "Color",
    Mode: "Mode",
    Light: "Light",
    Dark: "Dark",
    SemiDark: "SemiDark",
    CollapseSidebar: "Collapse sidebar",
    NavbarColor: "Navbar color",
    NavbarType: "Navbar type",
    FooterType: "Footer type",
    RightToLeft: "Right to left",
    Animation: "Animation",
    ZoomFade: "Zoom fade",
    SlideFade: "Slide fade",
    FadeBottom: "Fade bottom",
    Fade: "Fade",
    ZoomOut: "Zoom out",
    None: "None",
    Hidden: "Hidden",
    Static: "Static",
    Sticky: "Sticky",
    Floating: "Floating",
    Bookings: "Bookings",
    BookingDetail: "Booking detail",
    AccountNumber: "Account number",
    ContactName: "Contact name",
    Source: "Source",
    WayToPay: "way To Pay",
    NetValue: "Neto Value",
    TaxesValue: "Taxes Value",
    FeeValue: "Fee Value",
    FeeCustomized: "Fee Customized",
    MerchantValue: "Merchant Value",
    DiscountValue: "Discount Value",
    TotalValue: "Total Value",
    OtherValue: "Other value",
    ProductType: "Product Type",
    Origin: "Origin",
    Destination: "Destination",
    SupplierName: "Supplier",
    Itinerary: "Itinerary",
    Passengers: "Passengers",
    PassengerType: "Passenger Type",
    DocumentType: "Document Type",
    Phone: "Phone",
    FareValue: "Fare Value",
    Merchant: "Merchant",
    Value: "Value",
    Filters: "Filters",
    Supplier: "Supplier",
    Managed: "Managed",
    TicketNumber: "Ticket number",
    PaymentMethod: "Payment method",
    Stage: "Stage",
    Yes: "Yes",
    No: "No",
    List: "List",
    Clean: "Clean",
    Payment: "Payment",
    Settings: "Settings",
    Active: "Active",
    Inactive: "Inactive",
    ExpirationDate: "Expiration date",
    Create: "Create",
    Confirm: "Confirm",
    Purchases: "Purchases",
    CreatedBy: "Created by",
    Tracer: "Tracer",
    Guides: "Guide",
    Guide: "Guide",
    GuideCreationDate: "Guide creation date",
    EmailText: "Email text",
    Send: "Send",
    LastName: "Last name",
    Subject: "Subject",
    VisitUs: "Visit us",
    WeekNumber: "Week number",
    BVPoints: "Bv Points",
    RegisterCard: "Card registration",
    ConfigureFee: "Configure fee",
    CardRegistered: "Card Register",
    CardNote:
      "Note: Mr. User, remember that you only have a maximum number of 5 cards to register.",
    NewCard: "New card",
    CardType: "Card type",
    CardOwner: "Card owner",
    CardNumber: "CardNumber",
    ExpirationMonth: "Expiration month",
    ExpirationYear: "Expiration year",
    SecurityCode: "Security code",
    ZipCode: "Zip code",
    Names: "Names",
    Surnames: "Surnames",
    SelectCountry: "Select Country",
    Cancel: "Cancel",
    SelectedPlease: "Please select ",
    EnterPlease: "Please enter ",
    SelectState: "Select State",
    State: "State",
    EmailIncorrect: "Please enter correct email",
    Question: "Question",
    Answer: "Anwer",
    Coupons: "Coupons",
    UseDate: "Use date",
    Used: "Used",
    Generate: "Generate",
    Upgrade: "Upgrade",
    Membership: "Membership",
    MonthlyPayment: "Monthly payment",
    AutomaticMonthlyPayment: "Automatic monthly payment",
    Buy: "Buy",
    CreditCard: "Credit card",
    UserName: "User name",
    Accept: "Accept",
    Product: "Product",
    Welcome: "Welcome",
    SentPresentations: "Sent presentations",
    OpenedPresentations: "Opened presentations",
    NewMembers: "New members",
    WaitingForMeeting: "Waiting for meeting",
    SendNeo: "Send Neo",
    GenerateLink: "Generate link",
    GenericLink: "Generic link",
    Trackings: "Trackings",
    Copy: "Copy",
    MyLinks: "My links",
    Link: "Link",
    Indicative: "Indicative",
    BeneficiaryName: "Beneficiary name",
    BeneficiaryLastName: "Beneficiary last name",
    MembershipType: "Membership Type",
    Bill: "Bill",
    CreditCardType: "CreditCard Type",
    InstallmentNumber: "Installment Number",
    CreditCardNumber: "CreditCard Number",
    ChangeDate: "Change date",
    Resources: "Resources",
    Language: "Language",
    EWallet: "E-Wallet",
    News: "News",
    BankName: "Bank Name",
    ReferenceCode: "Reference Code",
    Attachment: "Attachment",
    Installments: "Installments",
    FinanceProduct: "Do you want to finance your product?",
    VerifyDate: "Verify date",
    IncludeNeo: "Do you want to buy Neo application?",
    Pending: "Pending",
    Verify: "Verify",
    IncompleteDocumentation: "Incomplete documentation",
    ResidenceCountry: "Residence country",
    ResidenceState: "Residence state",
    Enroller: "Enroller",
    Closer: "Closer",
    Presenter: "Presenter",
    AllCloser: "All closer",
    InstallmentPay: "Your installment is by only",
    BeingMember: "Being member!",
    Booking: "Booking",
    Documents: "Documents",
    Tracking: "Tracking",
    ExpirationDatePassport: "Expiration date passport",
    SpecificationName: "Specification name",
    SpecificationValue: "Specification value",
    BillingData: "Billing data",
    PreviousStage: "Previous stage",
    CurrentStage: "Current stage",
    Tourism: "Tourism",
    Year: "Year",
    Month: "Month",
    TopTravelAgents: "Top travel agents",
    LatestBookings: "Latest bookings",
    Sales: "Sales",
    WithoutInformation: "Without information",
    AddPayment: "Add payment",
    Payments: "Payments",
    Management: "Management",
    BirthDay: "Birth day",
    BirthMonth: "Birth month",
    BirthYear: "Birth year",
    Male: "Male",
    Female: "Female",
    Other: "Other",
    Hello: "Hello",
    MembershipJoin:
      "You have registrated a new member to our community. His/her distributor ID is:",
    OrderGenerated: "We were generated an invoce with order number:",
    Generated: "Generated",
    GenerateMembership: "Generate membership",
    SendWelcome: "Send welcome",
    NewMember: "New member",
    Nickname: "Nickname",
    IsFavorite: "Is favorite",
    Affiliations: "Affiliations",
    PaymentsConfirmed: "Payments confirmed",
    MembershipCountry: "Membership country",
    Disabled: "Disabled",
    UploadFile: "Upload",
    Declined: "Declined",
    Activities: "Activities",
    SendMoney: "Send money",
    Go: "Go",
    Remark: "Remark",
    Body: "Body",
    OtherProducts: "Other products",
    ExchangeRate: "Exchange rate",
    IsCompany: "It's a company",
    ConvertDistributor: "Would you like to be a Distributor IBO?",
    ImportantInformation: "Important information",
    Specifications: "Specifications",
    DetailPrices: "Detail prices",
    LenderServices: "Lender services",
    SendMoneyEwallet: "Would you like to send money to an E-wallet account?",
    Signature: "Signature",
    Investment: "Investment",
    Profitability: "Profitability",
    Invest: "Invest",
    BalanceForInvesting: "Balance for investing",
    CustomizedAVV: "Customized AVV",
    CustomizedO2T: "Customized One2trip",
    SendToBlockInvest: "Send to Block Invest",
    Certification: "CERTIFICATION",
    Quote: "QUOTE",
    QuoteHealth: "QUOTE HEALTH INSURANCE",
    QuoteTraveler: "QUOTE VIP TRAVEL INSURANCE",
    WeeksHonors: "Week's honors",
    BookingValue: "Booking value",
    Discount: "Discount",
    TelegramChannels:"Telegram channels",
    Categories: "Categories",
    FeaturedNews: "Featured news",
    RelatedArticles: "Related articles",
    FAQs: "FAQs",
    Get: "Get",
    MyManagement: "My management",
    ContactFormTitle: "Where can we contact you in case of an emergency?",
    TelephoneType: "Telephone Type",
    TelephoneNumber: "Telephone Number",
    Class: "Class",
    Airline: "Airline",
    FlightNumber: "Flight number",
    Passenger: "Passenger",
    AgentCommissionValue: "Agent commission value",
    OpenDocument: "Open document",
    DownloadCertification: "Download your IBO certificate",
    CustomerService: "Customer service",
    AffiliationDate: "Affiliation date",
    MyWayTopay: "My ways to pay",
    Day: "Day",
    Private: "Private",
    Order: "Order",
    Education: "Education",
    Add: "Add",
    Contact: "Contact",
    Packages: "Packages",
    WriteOrigin: "Write origin",
    WriteDestination: "Write destination",
    DepartureDate: "Departure date",
    ArrivalDate: "Arrival date",
    Hotels: "Hotels",
    MyCommissions: "My commissions",
    Incentives: "Incentives",
    IssueDate : "IssueDate",
    InitialAgent: "Initial agent",
    FinalAgent: "Final agent",
    Administration: "Administration",
    International: "International",
    AgentEmail: "Agent email",
    Publish: "Publish",
    SupportTeam: "Support team",
    Prospects: "Prospects",
    LandingPage: "Landing page",
    URLs: "URLs to access landing pages",
    LyraBinaryTree: "Lyra binary tree",
    Marketing: "Marketing",
    DownloadableResources: "Downloadable resources",
    QuoteMercantilVenezuela: "QUOTE VENEZUELA PLANS",
    QuoteMercantilPanama: "QUOTE PANAMA PLANS",
    QuoteMercantilRequest: "HEALTH POLICY REQUEST",
    QuoteMercantilSilverYears: "QUOTE SILVER YEARS",
  },
  es: {
    // ------------------------------- Alertas y mensajes -------------------------------
    MsgError: "Por favor, contactarse con el administrador",
    DontHaveCycles: "No has realizado ciclos",
    InformationEarnings:
      "Si necesita ver más información acerca de sus ganancias por período, por favor, seleccione la fila de la tabla. Los datos por defecto en la vista es del último año",
    ColorsTableGenealogy:
      'La columna "Nivel" tiene sus valores con colores, el significado es:',
    MsgNotAllow10Rows: "Solo se permite un máximo de 9 niveles",
    MsgDistNotExist: "El distribuidor ID no existe en su árbol binario",
    MsgWelcome: "Bienvenido de nuevo, por favor inicie sesión en su cuenta",
    MsgRecoverPassword:
      "Por favor, ingrese su correo electrónico y le enviaremos un código de verificación para restablecer o actualizar su contraseña.",
    MsgNewPassword: "Por favor, ingrese su nueva contraseña",
    MsgDifferentPassword: "Las contraseñas son diferentes",
    MsgInvalidPassword:
      "La contraseña debe tener un mínimo de 9 caracteres con mayúsculas, minúsculas, números y caracteres especiales, de lo contrario, el botón no se activará",
    MsgNotBinaryTree:
      "Ha ocurrido un error para recuperar el árbol binario. La información no es correcta. Por favor, verificar con el administrador",
    MsgFromLevelLess: 'El campo "{0}" debe ser menor que "{1}"',
    MsgUserNotExist: "El usuario ingresado no es un ditribuidor",
    MgsRestorePassword:
      'Puede restablecer su contraseña con la opción "¿Olvidó su contraseña?"',
    MsgNotFoundInfo: "No existe información",
    MsgStatusByPeriod:
      "Puede buscar su calificación u otro distribuidor de su línea descendente en diferentes períodos",
    MsgConsiderInformation:
      "Por favor, considere la siguiente información importante:",
    MsgPayTransaction: "Se cobrará USD $1 por cada transferencia realizada",
    MsgDaysToPayment:
      "Las transferencias estarán disponibles en su billetera electrónica el próximo miércoles después de que se solicitó la transferencia a las 16:00 ET, más tarde.",
    MsgPaymentMade: "El pago se ha realizado correctamente",
    MsgPaymentNotMade: "El pago no se ha realizado correctamente",
    MsgRefreshPage: "Por favor, refresque la página para obtener los cambios",
    MsgNotEnoughMoney:
      "Debe tener al menos USD $2 para realizar la transferencia",
    MsgNotPayment: "No estamos procesando transferencias en este momento",
    MsgNotTransferEwallet: "No estamos procesando transferencias a Ewallet en este momento",
    MsgWalletNotExist:
      "El usuario no existe en la billtera electrónica. Por favor, necesita crear una cuenta para recibir pagos.",
    MsgYouHaveEarned: "Has ganado",
    MsgTipAndFee: "en propinas y fee personalizado",
    MsgStageBill:
      "Únicamente las reservas que estén en facturación serán pagadas",
    MsgTax:
      'Se cobrarán impuestos por cada reserva. Ver la columna "Impuestos" en el detalle',
    MsgTotalPendingCharges:
      " Se cargará a su transferencia. Es posible que haya recibido pagos dobles o que no hayamos realizado el descuento de USD $ 1 por otras transferencias realizadas",
    MsgWriteToCustomerService:
      "Si tiene alguna pregunta, usted debe escribir a servicioalcliente@visiontravel.net con la descripción de su problema",
    MsgOneTransferAtDay: "Solo puede realizar una transferencia al día",
    MsgRequiredField: "Debe completar los campos obligatorios",
    MsgCustomizeTheme: "Personaliza y visualiza en tiempo real",
    MsgTypeTraceGuide:
      "Si usted crea una ENLACE GENÉRICO significa que, va a enviar un enlace rastreable, el cual, puede enviar a múltiples personas al tiempo y al momento de ver la presentación, se le pedirá al prospecto unos datos básicos, que quedarán registrados en su lista de prospectos.",
    MsgTypeTracer:
      "Si usted crea un ENLACE ÚNICO significa que, va a enviar un rastreador único para una única persona y usted deberá ingresar unos datos básicos del prospecto.",
    MsgSaved: "El registro ha sido guardado con éxito",
    MsgNotAllowGenealogy:
      "Usted no puede ver la genealogía. Si quiere obtener los datos, debe actualizar su plan o el hacer pago de su mensualidad",
    MsgHaveNeo: "Usted ya tiene la aplicación Neo",
    MsgAccountDiscount:
      "Descontaremos el pago de la compra de la siguiente cuenta",
    MsgWorking:
      "Estamos trabajando en esta nueva funcionalidad. Esperala pronto",
    MsgAllRequiredField: "Todos los campos son obligatorios",
    TermsConditionsTitle: "Términos y condiciones",
    TermsConditionsContent1: "* Lea atentamente el contenido de este texto",
    TermsConditionsContent2: "¿QUÉ PUEDO HACER CON ESTE FORMULARIO?",
    TermsConditionsContent3:
      "Configure la tarifa personalizada para los alojamientos disponibles en los diferentes PCC que se muestran en la agencia de viajes virtual.",
    TermsConditionsContent4:
      "Configure la tarifa personalizada para alojamiento, vuelos, seguros, automóviles y paquetes del PCC de los Estados Unidos.",
    TermsConditionsContent5:
      "Configure el porcentaje entre 1 y 10 para la tarifa personalizada.",
    TermsConditionsContent6:
      "Si desea modificar la tarifa configurada, debe eliminar el registro guardado de la opción disponible en la columna 'Opciones' y volver a configurar la tarifa para ese producto y PCC.",
    TermsConditionsContent7: "¿DÓNDE SE APLICA?",
    TermsConditionsContent8:
      "La tarifa personalizada se agregará a los cargos administrativos de la reserva, aumentando así el valor total de la reserva.",
    TermsConditionsContent9: "¿CUÁNDO SERÁ REFLEJADO EN MI CUENTA?",
    TermsConditionsContent10:
      "El cargo adicional cobrado en una reserva se cargará a su cuenta de billetera electrónica en las fechas establecidas por Vision Travel.",
    TermsConditionsContent11:
      "Tenga en cuenta que el valor que recibirá por la tarifa personalizada será menor que el monto cobrado, debido a los costos de la intermediación financiera.",
    TermsConditionsContent12:
      "¿CÓMO VISUALIZO LA TARIFA ADICIONAL APLICADA A UNA RESERVA?",
    TermsConditionsContent13:
      "Podrá ver el valor de la tarifa aplicada a una reserva cuando ingrese los detalles de la reserva desde la oficina de turismo en línea.",
    TermsConditionsContent14: "ADVERTENCIA...",
    TermsConditionsContent15:
      "Recuerde que los precios de reserva deben estar dentro de los establecidos en el mercado. El mal uso de la opción de tarifa personalizada puede causar un bloqueo permanente para la aplicación de los valores configurados.",
    MsgCopy: "Texto copiado exitosamente",
    MsgAcquireMembership:
      "Estás ingresando un nuevo miembro a tu comunidad con el producto {0} por",
    MsgAcquireMembership1:
      "¿Estás preparado para ser parte de la mejor comunidad?",
    MsgAcquireMembership2: "¡ Afíliate YA !",
    MsgPendingMembership: "Usted debe {0} años de membresía",
    MsgPurchaseVerify:
      "Su compra será verificada. Por favor, debe verificar su Oficina Virtual para conocer el estado de la solicitud",
    MsgPurchaseCompleted: "Su solicitud se ha completado exitosamente",
    MsgNotUpgrade: "No aplica para hacer una actualización",
    MsgNotAttachment: "Usted debe cargar un documento",

    MsgEmailSent: "El correo ha sido enviado",
    MsgSendContract:
      "Hemos enviado el contrato de membresía al correo electrónico {0}. Debe ser firmado por {1} para completar el registro correctamente.",
    MsgNotMonthlyPayment:
      "No aplica para hacer el pago de su mensualidad. Su fecha de expiración es: {0}",
    MsgInfotraxOffline:
      "Le pedimos disculpas por las molestias ocasionadas, en este momento nuestro sistema de comisiones y genealogía no está funcionando correctamente",
    MsgTransferAccount: "Transferencia a la cuenta #{0}",
    MsgJoinTelegram: "Puedes unirte a nuestra comunidad de Telegram para recibir notificaciones relacionadas con tus compras, sean de turismo o binario. No te preocupes, serán sólo relacionadas con tus movimientos.",
    MsgWhatWait: "¿Qué esperas? ¡Únete!",
    MsgGenerate: "Recuerda que, cuando pagas en línea y los pagos son confirmados (E-wallet), solo debes hacer clic en el botón 'Generar' y listo, tu orden y accesos han sido generados. ¡Hazlo fácil!",
    MsgLinkMembership: "Puedes enviar este enlace a tus clientes y ellos harán su afiliación. Debes estar atento para completar la información del binario. Si quieres recibir la notificación cuando el cliente finalice el proceso, ¡Únete a nuestra comunidad de Telegram!. Haga clic en el botón TELEGRAM o en su perfil.",
    MsgNotInvest: "El sistema ha fallado completando la inversión en Block Invest",
    MsgInvest: "¡Felicitaciones! Has realizado una inversión en Block Invest. ¡TE DESEAMOS BUENA SUERTA CON LAS GANANCIAS!",
    MsgNotBalance: "El número de cuenta #{0} tiene un saldo de USD {1}, es menos que la cantidad",
    MsgFinancialCharge: "¡Recuerda! Te cobraremos un porcentaje del total de la transacción de cada fee personalizado por concepto de 'Cargos financieros', esto en virtud de la recaudación del dinero...",
    MsgFinancialChargeAdded: "El porcentaje agregado que vaya del 1% al 7% de fee, tendrá un cargo fijo del 7%.",
    MsgFinancialChargeFee: "El porcentaje de fee personalizado que vaya del 8% al 15%, tendrá un cargo equivalente al porcentaje que agregaste.",
    MsgStartDateAfterEndDate: "La fecha de inicio debe ser menor que la fecha fin",
    MsgItineraryBeforeNow: "Las fechas del itinerario deben ser mayor o igual que la fecha actual",
    MsgItineraryAfterArrival: "La fecha de salida debe ser menor que la fecha de regreso",
    //------------------------------- Menú -------------------------------
    Title: "Mi oficina",
    Home: "Inicio",
    AccessToPlatforms: "Accesos a plataformas",
    Genealogy: "Genealogía",
    Orders: "Órdenes",
    General: "General",
    Commissions: "Comisiones",
    StatusByPeriod: "Estado por período",
    Customized: "Personalizadas",
    TravelPoints: "Puntos de turismo",
    //------------------------------- Descripciones -------------------------------

    Description: "Descripción",
    CarryInLastPeriod: "Acumulado desde el último período",
    CurrentPeriod: "Período actual",
    CarryNextPeriod: "Acumulado para el siguiente período",
    BusinessCenters: "Centros de negocio",
    BusinessCenter: "Centro de negocios",
    Center: "Centro",
    NewCustomers90: "Clientes nuevos los últimos 90 días",
    DirectSales90: "Ventas directas los últimos 90 días",
    DirectSales365: "Ventas directas los últimos 365 días",
    Last5Periods: "Últimos cinco períodos",
    Number: "Número",
    PVAmount: "Cantidad PV",
    Period: "Período",
    DistCountryCode: "Código país dist.",
    ShipToCountry: "País para emitir",
    ShipToName: "Nombre para emitir",
    Date: "Fecha",
    Options: "Opciones",
    Next: "Siguiente",
    Back: "Atrás",
    Finish: "Finalizar",
    LastWeek: "Semana pasada",
    Currently: "Actualmente",
    Total: "Total",
    Volumen: "Volumen",
    Available: "Disponible",
    Profile: "Perfil",
    View: "Ver",
    Download: "Descargar",
    Downloads: "Descargas",
    OrderHistory: "Historial de órdenes",
    BasicInformation: "Información básica",
    DocumentNumber: "Número documento",
    BirthDate: "Fecha nacimiento",
    Email: "Correo electrónico",
    Mobile: "Celular",
    Gender: "Género",
    CountryOperation: "País operación",
    Nationality: "Nacionalidad",
    ResidenceCity: "Ciudad de residencia",
    Address: "Dirección",
    Return: "Volver",
    AllRightsReserved: "Todos los derechos reservados",
    HandCraftedMMade: "Hecho a mano y con",
    Invoice: "Factura",
    OrderNumber: "Número orden",
    OrderDate: "Fecha orden",
    ShipTo: "Emitir a",
    BillTo: "Facturar a",
    Item: "Número",
    Code: "Código",
    QtyShip: "Cantidad emitida",
    QtyOrder: "Cantidad orden",
    TotalVolumen: "Volumen total",
    Price: "Precio",
    Tax: "Impuestos",
    Taxable: "Gravable",
    Subtotal: "Subtotal",
    Comments: "Comentarios",
    ShippingAndHandling: "Emisión y manipulación",
    TotalAmountDue: "Importe total adeudado",
    PaymentsReceived: "Pagos recibidos",
    ReceiptAmount: "Cantidad de recibo",
    TotalCashReceipts: "Total recibos de efectivo",
    InvoceBalance: "Saldo de factura",
    Close: "Cerrar",
    NoAvailableData: "No hay datos disponibles",
    LeftVolumen: "Volumen izquierda",
    RightVolumen: "Volumen derecha",
    ExportAs: "Exportar como",
    Logout: "Cerrar sesión",
    LeftBvTravelPoint: "Izquierda - Puntos de viaje (BV)",
    RightBvTravelPoint: "Derecha - Puntos de viaje (BV)",
    Qualifications: "Calificaciones",
    Rank: "Rango",
    VolumenPersonal: "Volumen personal",
    ViewEnrolledsTotal: "Ver total de patrocinados",
    CyclesRequired: "Ciclos requeridos",
    CyclesTotal: "Total de ciclos realizados",
    PersonalEnrollees: "Patrocinados personales",
    Left: "Izquierda",
    Right: "Derecha",
    SinceWeek: "Desde la semana",
    UntilWeek: "Hasta la semana",
    Select: "Seleccione",
    Search: "Buscar",
    Summary: "Resumen",
    StartDate: "Fecha inicio",
    EndDate: "Fecha fin",
    CheckDate: "Fecha verificación",
    CheckAmount: "Cantidad verificada",
    EarningsAmount: "Cantidad de ganancias",
    CurrencyCode: "Código moneda",
    Currency: "Moneda",
    TransactionNumber: "Número transacción",
    EarningsSummaryByPeriod: "Resumen de ganancias por período",
    RetailCommission: "Comisión turismo",
    FastStart: "Bono de inicio rápido",
    CycleCommission: "Ciclo de comisión",
    MatchingBonus: "Bono de igualdad",
    BaseBonus: "Bono base",
    LifestyleBonus: "Bono de estilo de vida",
    EarningsByCommissionType: "Ganancias por tipo de comisión",
    PeriodDetails: "Detalle de período",
    DownloadPDF: "Descargar PDF",
    Detail: "Detalle",
    DistributorID: "ID distribuidor",
    Name: "Nombre",
    PaidRank: "Rango pago",
    PinRank: "Pin rango",
    PercentContributed: "Porcentaje contribuído",
    AmountContributed: "Cantidad contribuída",
    Amount: "Cantidad",
    Associated: "Asociado",
    Silver: "Plata",
    Gold: "Oro",
    Platinum: "Platino",
    Diamond: "Diamante",
    DoubleDiamond: "Doble Diamante",
    TripleDiamond: "Triple Diamante",
    Crown: "Corona",
    CrownAmbassador: "Embajador de la Corona",
    TotalCommissionsEarned: "Total de comisiones ganadas",
    Country: "País",
    Percent: "Porcentaje",
    IsPercent: "Es porcentaje",
    Level: "Nivel",
    PaidLevel: "Nivel pagado",
    Bonus: "Bono",
    CycleNumber: "Ciclo número",
    Step: "Paso",
    BinaryTree: "Árbol binario",
    Status: "Estado",
    RequiredEnrolleds: "Patrocinados requeridos",
    EndRank: "Rango final",
    Side: "Lado",
    Sponsor: "Colocación",
    FromLevel: "Desde el nivel",
    UntilLevel: "Hasta el nivel",
    All: "Todos",
    Customer: "Cliente",
    Terminated: "Terminado",
    Distributor: "Distribuidor",
    MemberType: "Tipo de miembro",
    Suspended: "Suspendido",
    AmountRows: "Cantidad de filas",
    Alert: "Alerta",
    Error: "Error",
    Success: "Éxito",
    Start: "Inicio",
    Unclassified: "Sin clasificar",
    Login: "Iniciar sesión",
    Password: "Contraseña",
    ForgotPassword: "¿Olvidó su contraseña?",
    RecoverPassword: "Recuperar su contraseña",
    Recover: "Recuperar",
    ConfirmPassword: "Confirmar contraseña",
    VerificationCode: "Código de verificación",
    ResetPassword: "Restablecer contraseña",
    Reset: "Restablecer",
    CustomizedCommissions: "Comisiones personalizadas",
    TipAndCustomizedFee: "Propinas y fee personalizado",
    FeeTotal: "Total fee",
    TipsTotal: "Total propinas",
    Pay: "Pagar",
    Paid: "Pagado",
    WithoutPay: "Sin pagar",
    BookingCode: "Código reserva",
    CreationDate: "Fecha creación",
    PaymentDate: "Fecha pago",
    Type: "Tipo",
    Tip: "Propina",
    CustomizedFee: "Fee personalizado",
    Transfer: "Transferir",
    Congratulations: "Felicitaciones",
    TotalTransactions: "Total transacciones",
    AdjustmentTip: "Ajuste de propina",
    AdjustmentCustomizedFee: "Ajuste de fee personalizado",
    TotalPendingCharges: "Total cargos pendientes",
    PendingCharges: "Cargos pendientes",
    FinancialCharge: "Cargos financieros",
    Action: "Acción",
    Update: "Editar",
    Save: "Guardar",
    ThemeCustomized: "Tema personalizado",
    LayoutType: "Tipo de diseño",
    Vertical: "Vertical",
    Horizontal: "Horizontal",
    Color: "Color",
    Mode: "Modo",
    Light: "Luz",
    Dark: "Oscuro",
    SemiDark: "Semi-oscuro",
    CollapseSidebar: "Colapsar barra lateral",
    NavbarColor: "Color barra de navegación",
    NavbarType: "Tipo barra de navegación",
    FooterType: "Tipo pie de página",
    RightToLeft: "Derecha a izquierda",
    Animation: "Animación",
    ZoomFade: "Desvanecer zoom",
    SlideFade: "Desvanecer página",
    FadeBottom: "Desvanecer hacia abajo",
    Fade: "Desvacenecer",
    ZoomOut: "Disminuir zoom",
    None: "Ninguno",
    Hidden: "Esconder",
    Static: "Estático",
    Sticky: "Adhesivo",
    Floating: "Flotante",
    Bookings: "Reservas",
    BookingDetail: "Detalle de la reserva",
    AccountNumber: "Número de cuenta",
    ContactName: "Nombre contacto",
    WayToPay: "Forma de pago",
    NetValue: "Valor neto",
    TaxesValue: "Valor impuesto",
    FeeValue: "Valor Fee",
    FeeCustomized: "Fee Personalizado",
    MerchantValue: "Valor Merchant",
    DiscountValue: "Valor de descuento",
    TotalValue: "Valor total",
    OtherValue: "Otro valor",
    ProductType: "Tipo de producto",
    Origin: "Origen",
    Destination: "Destino",
    SupplierName: "Proveedor",
    Itinerary: "Itinerario",
    Passengers: "Pasajeros",
    PassengerType: "Tipo pasajero",
    DocumentType: "Tipo documento",
    Phone: "Número telefónico",
    FareValue: "Valor Fare",
    Merchant: "Merchant",
    Value: "Valor",
    Filters: "Filtros",
    Supplier: "Proveedor",
    Managed: "Esta Gestionada",
    TicketNumber: "Número de boleto",
    Source: "Fuente",
    PaymentMethod: "Forma de pago",
    Stage: "Etapa",
    Yes: "Sí",
    No: "No",
    List: "Lista",
    Clean: "Limpiar",
    Payment: "Pago",
    Settings: "Ajustes",
    Active: "Activo",
    Inactive: "Inactivo",
    ExpirationDate: "Fecha expiración",
    Create: "Crear",
    Confirm: "Confirmar",
    Purchases: "Compras",
    CreatedBy: "Creado por",
    Tracer: "Rastreador",
    Guides: "Guías",
    Guide: "Guía",
    GuideCreationDate: "Fecha creación guía",
    EmailText: "Texto correo electrónico",
    Send: "Enviar",
    LastName: "Apellido",
    Subject: "Asunto",
    VisitUs: "Visítanos",
    WeekNumber: "Número semana",
    BVPoints: "Puntos BV",
    CardNote:
      "Nota: Señor Usuario, recuerde que solo tiene un número máximo de 5 tarjetas para registrar.",
    NewCard: "Nueva tarjeta",
    CardType: "Tipo tarjeta",
    CardOwner: "Propietario",
    CardNumber: "Numero tarjeta",
    ExpirationMonth: "Mes de expiración",
    ExpirationYear: "Año de expiración",
    SecurityCode: "Código de seguridad",
    ZipCode: "Código postal",
    Names: "Nombres",
    Surnames: "Apellidos",
    SelectCountry: "Seleccione país",
    SelectState: "Seleccione estado",
    State: "Estado",
    Cancel: "Cancelar",
    SelectedPlease: "Seleccione por favor ",
    EnterPlease: "Ingrese por favor ",
    EmailIncorrect: "Ingrese por favor un email válido",
    Question: "Pregunta",
    Answer: "Respuesta",
    Coupons: "Cupones",
    UseDate: "Fecha de uso",
    Used: "Usado",
    Generate: "Generar",
    Upgrade: "Promover de nivel",
    Membership: "Afiliación",
    MonthlyPayment: "Mensualidad",
    AutomaticMonthlyPayment: "Mensualidad automática",
    Buy: "Comprar",
    CreditCard: "Tarjeta de crédito",
    UserName: "Nombre usuario",
    FeeNote:
      "Esta opción le permitirá configurar el fee adicional que será sumado al valor total de la reserva. Este valor será consignado posteriormente en su cuenta de ewallet. ",
    MoreInformation: "Más información",
    Accept: "Aceptar",
    Product: "Producto",
    TypeFee: "Tipo fee",
    RegisterFee: "Registro de Fee",
    Welcome: "Bienvenido",
    SentPresentations: "Presentaciones enviadas",
    OpenedPresentations: "Presentaciones abiertas",
    NewMembers: "Miembros nuevos",
    WaitingForMeeting: "Esperando por reunión",
    SendNeo: "Enviar Neo",
    GenerateLink: "Generar link",
    Trackings: "Seguimientos",
    GenericLink: "Enlace genérico",
    Copy: "Copiar",
    MyLinks: "Mis links",
    Link: "Link",
    Indicative: "Indicativo",
    BeneficiaryName: "Nombre beneficiario",
    BeneficiaryLastName: "Apellido beneficiario",
    MembershipType: "Tipo afiliación",
    Bill: "Factura",
    CreditCardType: "Tipo Tarjeta de crédito",
    InstallmentNumber: "Couta",
    CreditCardNumber: "Número TC",
    ChangeDate: "Fecha de cambio",
    Resources: "Recursos",
    Language: "Idioma",
    EWallet: "Billetera electrónica",
    News: "Noticias",
    BankName: "Nombre de Banco",
    ReferenceCode: "Código de referencia",
    Attachment: "Adjunto",
    Installments: "Coutas",
    FinanceProduct: "¿Quieres financiar tu producto?",
    VerifyDate: "Fecha de verificación",
    IncludeNeo: "¿Quieres comprar la aplicación de Neo?",
    Pending: "Pendiente",
    Verify: "Verificación",
    IncompleteDocumentation: "Documentación incompleta",
    ResidenceCountry: "País de residencia",
    ResidenceState: "Estado de residencia",
    Enroller: "Patrocinador",
    Presenter: "Presentador",
    AllCloser: "Todo cerrador",
    InstallmentPay: "Tu cuota es por solo",
    BeingMember: "¡Ser miembro!",
    Closer: "Cerrador",
    Booking: "Reserva",
    Documents: "Documentos",
    Tracking: "Trazabilidad",
    ExpirationDatePassport: "Fecha vencimiento pasaporte",
    SpecificationName: "Nombre especificación",
    SpecificationValue: "Valor especificación",
    BillingData: "Datos facturación",
    PreviousStage: "Etapa anterior",
    CurrentStage: "Etapa actual",
    Tourism: "Turismo",
    Customers: "Clientes",
    Year: "Año",
    Month: "Mes",
    TopTravelAgents: "Top agentes de viajes",
    LatestBookings: "Últimas reservas",
    Sales: "Ventas",
    WithoutInformation: "Sin información",
    AddPayment: "Añadir pago",
    Payments: "Pagos",
    Management: "Gestión",
    BirthDay: "Día de nacimiento",
    BirthMonth: "Mes de nacimiento",
    BirthYear: "Año de nacimiento",
    Male: "Masculino",
    Female: "Femenino",
    Other: "Otro",
    Hello: "Hola",
    MembershipJoin:
      "Has registrado un nuevo miembro a nuestra comunidad. Su distribuidor ID es:",
    OrderGenerated: "Se generó una factura con el número de orden:",
    Generated: "Generada",
    GenerateMembership: "Generar afiliación",
    SendWelcome: "Enviar bienvenida",
    NewMember: "Nuevo miembro",
    Nickname: "Alias",
    IsFavorite: "Es favorita",
    Affiliations: "Afiliaciones",
    PaymentsConfirmed: "Pagos confirmados",
    MembershipCountry: "País de afiliación",
    Disabled: "Inhabilitar",
    UploadFile: "Cargar",
    Declined: "Declinado",
    Activities: "Movimientos",
    SendMoney: "Enviar dinero",
    Go: "Ir",
    Remark: "Observación",
    Body: "Cuerpo",
    OtherProducts: "Otros productos",
    ExchangeRate: "Tasa cambio",
    IsCompany: "Es una compañía",
    ConvertDistributor: "¿Le gustaría convertirse en un IBO Distribuidor?",
    ImportantInformation: "Información importante",
    Specifications: "Especificaciones",
    DetailPrices: "Precios detallados",
    LenderServices: "Prestador servicios",
    SendMoneyEwallet: "¿Te gustaría enviar dinero a una cuenta de E-wallet?",
    Signature: "Firma",
    Investment: "Inversión",
    Profitability: "Rentabilidad",
    Invest: "Invertir",
    BalanceForInvesting: "Saldo para invertir",
    CustomizedAVV: "Personalizadas AVV",
    CustomizedO2T: "Personalizadas One2trip",
    SendToBlockInvest: "Enviar a Block Invest",
    Certification: "CERTIFICACIÓN",
    Quote: "COTIZAR",
    QuoteHealth: "COTIZAR SEGURO DE SALUD",
    QuoteTraveler: "COTIZAR SEGURO VIAJERO VIP",
    WeeksHonors: "Distinciones de la semana",
    BookingValue: "Valor reserva",
    Discount: "Descuento",
    TelegramChannels:"Canales Telegram",
    Categories: "Categorías",
    FeaturedNews: "Noticias destacadas",
    RelatedArticles: "Artículos relacionados",
    FAQs: "FAQs",
    Get: "Obtener",
    MyManagement: "Mi gestión",
    ContactFormTitle:"¿A que teléfono podemos contactarte en caso de emergencia?",
    TelephoneType: "Tipo teléfono",
    TelephoneNumber: "Número telefónico",
    Class: "Clase",
    Airline: "Aerolínea",
    FlightNumber: "Número vuelo",
    Passenger: "Pasajero",
    AgentCommissionValue: "Valor comisión agente",
    OpenDocument: "Abrir documento",
    DownloadCertification: "Descarga tu certificado de IBO",
    CustomerService: "Customer service",
    AffiliationDate: "Fecha afiliación",
    MyWayTopay: "Mis formas de pago",
    Day: "Día",
    Private: "Privado",
    Order: "Orden",
    Education: "Educación",
    Add: "Agregar",
    Contact: "Contacto",
    Packages: "Paquetes",
    WriteOrigin: "Escribir origen",
    WriteDestination: "Escribir destino",
    DepartureDate: "Fecha salida",
    ArrivalDate: "Fecha llegada",
    Hotels: "Hoteles",
    MyCommissions: "Mis comisiones",
    Incentives: "Incentivos",
    IssueDate : "Fecha emisión",
    InitialAgent: "Agente inicial",
    FinalAgent: "Agente final",
    Administration: "Administración",
    International: "Internacional",
    AgentEmail: "Email asesor",
    Publish: "Publicar",
    SupportTeam: "Team de apoyo",
    Prospects: "Prospectos",
    LandingPage: "Landing page",
    LyraBinaryTree: "Árbol binario de Lyra",
    Marketing: "Mercadeo",
    DownloadableResources: "Recursos descargables",
    QuoteMercantilVenezuela: "COTIZAR PLANES VENEZUELA",
    QuoteMercantilPanama: "COTIZAR PLANES PANAMA",
    QuoteMercantilRequest: "SOLICITUD PÓLIZA SALUD",
    QuoteMercantilSilverYears: "COTIZAR AÑOS PLATEADOS",
  }
};
