var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "convstooltip",
      staticClass: "con-vs-tooltip",
      on: {
        mouseleave: _vm.mouseleavex,
        mouseenter: _vm.mouseenterx,
        mouseup: _vm.destroy
      }
    },
    [
      _c("transition", { attrs: { name: "tooltip-fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.active,
                expression: "active"
              }
            ],
            ref: "vstooltip",
            staticClass: "vs-tooltip",
            class: [
              "vs-tooltip-" + (_vm.positionx || _vm.position),
              "vs-tooltip-" + _vm.color,
              { "after-none": _vm.noneAfter }
            ],
            style: _vm.style
          },
          [
            _vm.title ? _c("h4", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
            _vm._v("\n      " + _vm._s(_vm.text) + "\n    ")
          ]
        )
      ]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }